/* General Styles */



/* For better animation or transition */
.nav {
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.nav.show {
  transform: translateX(0);
}
.hamburger {
  cursor: pointer;
  font-size: 24px;
  display: block;
  margin-left: auto; /* Pushes the icon to the right */
  padding-right: 20px; /* Adjust the value to move it further to the right */
}


.nav ul {
  list-style: none;
  padding: 0;
}

.nav ul li {
  margin: 10px 0;
}

/* Ensure the navigation is hidden initially */
.nav {
  display: none;
}

.nav.show {
  display: block;
}

@media (min-width: 768px) {
  .hamburger {
    display: none; /* Hide hamburger on larger screens */
    
  }

  .nav {
    display: flex; /* Show navigation by default on larger screens */
    transform: none; /* Reset any transformations */
  }

  .nav.show {
    transform: none;
  }
}

html {
  scroll-behavior: smooth;
}
/* Apply to all sections within main-content */
.main-content section {
  scroll-margin-top: 80px; /* Adjust this value to match your header's height */
}
@media (max-width: 768px) {
  .main-content section {
    scroll-margin-top: 100px; /* Adjust if header height changes on smaller screens */
  }
}


.header {
  display: flex;
  justify-content: space-around; /* Space items evenly with space around them */
  align-items: center;
  padding: 15px 30px; /* Adjust padding for more or less space */
  background-color: #ffffff; /* Change background color to white */
  border-bottom: 1px solid #ddd; /* Add a bottom border for separation */
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* Ensure full width coverage */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  z-index: 1000;
}





.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 100%;
  height: auto;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00c4cc;
  line-height: 1.5;
  text-align: center;
}



/* Tablet devices and smaller */
@media (max-width: 768px) {
  .title {
    font-size: 1.3rem; /* Slightly smaller font size for tablets */
    color: #00c4cc;
  }
}

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .title {
    font-size: 1.1rem; /* Smaller font size for mobile devices */
    color: #00c4cc;
  }
}




.nav ul {
  display: flex;
  list-style: none;
  gap: 40px;
  font-weight: 650;
}






.nav a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.nav a:hover {
  color: #007bff;
}



section {
  padding: 4rem 2rem;
  text-align: center;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.home {
  position: relative;
  overflow: hidden; /* Ensures nothing goes out of bounds */
  width: 94%; /* Ensures the container covers the full width */
  height: 100vh; /* Full viewport height */
  margin-top: 80px; /* Adjust this value to match the height of the header */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .home {
    height: auto; /* Adjusts height to content on smaller screens */
    padding: 20px;
    margin-top: 60px; /* Adjust this value to match the height of the header on smaller screens */
    /* Adds padding to ensure content isn't too close to the edges */
  }
}

@media (max-width: 480px) {
  .home {
    padding: 10px;
    margin-top: 60px; /* Further adjusts margin for very small screens */
    /* Further adjusts padding for very small screens */
  }
}




.home-content {
  position: absolute;
  top: 50%; /* Adjusted for different vertical centering */
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333; /* Changed text color for better readability */
  text-align: center;
  z-index: 10; /* Ensure it appears on top of the slider */
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background for readability */
  border-radius: 15px; /* Increased border-radius for rounded corners */
  padding: 2rem; /* Increased padding for more space */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added shadow for a lifted effect */
}

.home-content h1 {
  font-size: 2.5rem; /* Slightly smaller font size */
  margin-bottom: 0.5rem; /* Reduced margin for a tighter look */
}

.home-content p {
  font-size: 1.1rem; /* Slightly smaller font size */
  margin-bottom: 1.5rem; /* Reduced margin for a tighter look */
}


/* Tablet devices and smaller */
@media (max-width: 768px) {
  .home-content {
    padding-top: 230px;
  }

  .home-content h1 {
    font-size: 1.3rem;
    color: #00c4cc;
  }

  .home-content p {
    font-size: 0.2rem;
    color: #00c4cc;
  }
}

/* Mobile devices (small screens) */
@media (max-width: 480px) {
  .home-content {
    padding-top: 230px;
  }

  .home-content h1 {
    font-size: 0.9rem;
    color: #00c4cc;
  }

  .home-content p {
    font-size: 0.2rem;
    color: #00c4cc;
  }
}



.cta-button {
  background-color: #3498db;
  color: white;
  padding: 1rem 2rem; /* Default padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem; /* Default font size */
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #2980b9;
}

.services{
  margin: 2rem 0;
  padding: 4rem 2rem;
  top: 20px;
}
.technologies,
.about,
.process,
.testimonials,
.contact {
  background: #f9f9f9;
  margin: 2rem 0;
  padding: 4rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.service-list,
.process-steps,
.testimonial-list,
.tech-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-item,
.tech-item,
.step,
.testimonial-item {
  flex: 1 1 300px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.tech-icons {
  display: flex;
  justify-content: space-around;
}



.footer {
  background-color: #2c3e50;
  color: white;
  padding: 1rem;
  text-align: center;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  

  .nav ul {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-left: 0;
  }

  .nav a {
    font-size: 1rem;
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  

  

  .cta-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .services,
  .technologies,
  .about,
  .process,
  .testimonials,
  .contact {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .service-item,
  .tech-item,
  .step,
  .testimonial-item {
    flex: 1 1 100%;
    margin: 1rem 0;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.75rem;
  }

  .submit-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .logo {
    width: 90%;
  }

  .nav ul {
    gap: 10px;
  }

  .home-content h1 {
    font-size: 1.5rem;
  }

  .home-content p {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .services,
  .technologies,
  .about,
  .process,
  .testimonials,
  .contact {
    padding: 1rem 0.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.5rem;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}
